<template>
    <div class="Analytics" :class="{ 'is-loading': isFetchingData }">
        <Headline style="">
            <h1 style="">
                Analyse Ihres Unternehmens
            </h1>
        </Headline>
        <Toolbar>
            <div>
                <Datepicker @onChange="handleDateChange" :startDate="selectedFrom" monthPicker />
                <Dropdown
                    :items="dropdownValues"
                    :selected="selectedValue && selectedValue.value"
                    variant="single-column"
                    :hasRemoveIcon="false"
                    placeholder="Kategorie auswählen"
                    @onItemSelect="handleDropdownSelect"
                />
                <Button @onClick="handleQuery" :isLoading="isFetchingData">
                    Anzeigen
                </Button>
            </div>
            <div>
                <Download
                    :title="isMobile ? 'CSV' : 'Download CSV'"
                    type="csv"
                    @onDownload="handleDownload('csv')"
                />
            </div>
        </Toolbar>
        <AnalyticsTable
            :shifts="shifts"
            :startAt="startAt"
            :endAt="endAt"
            :option="selectedValue"
            @onCVSChange="handleCSVChange"
            @onRowHover="handleRowHover"
            @onDriverClick="handleDriverClick"
        />
    </div>
</template>

<script>
import axios from 'axios';
import { endOfMonth, formatISO, startOfMonth } from 'date-fns';

import Datepicker from '@/components/widgets/Datepicker';
import Button from '@/components/widgets/Button';
import Download from '@/components/widgets/Download';
import Dropdown from '@/components/widgets/Dropdown';
import Headline from '@/components/Headline';
import Toolbar from '@/components/Toolbar';
import AnalyticsTable from './components/AnalyticsTable';

export default {
    name: 'Analytics',
    components: {
        AnalyticsTable,
        Datepicker,
        Button,
        Download,
        Dropdown,
        Headline,
        Toolbar,
    },
    data() {
        return {
            dropdownValues: [{ id: 'driver', value: 'Fahrer' }, { id: 'car', value: 'Fahrzeuge' }],
            selectedValue: { id: 'driver', value: 'Fahrer' },
            isFetchingData: false,
            shifts: [],
            drivers: JSON.parse(localStorage.getItem('drivers')).filter(d => d.isVisible),
            cars: JSON.parse(localStorage.getItem('cars')),
            selectedFrom: startOfMonth(new Date()),
            selectedTo: endOfMonth(new Date()),
            totalRevenue: 0,
            csv: null,
            startAt: startOfMonth(new Date()),
            endAt: endOfMonth(new Date()),
        };
    },
    computed: {
        isMobile() {
            return window.innerWidth < 500;
        },
    },
    methods: {
        handleDropdownSelect({ item }) {
            this.selectedValue = item;
        },
        handleDriverSelect({ item }) {
            this.selectedEmployee = item;
        },
        handleCarSelect({ item }) {
            this.selectedCar = item;
        },
        handleDateChange({ from, to }) {
            this.selectedFrom = from;
            this.selectedTo = to;
        },
        handleDownload() {
            // Create a Blob and trigger download
            const blob = new Blob([this.csv], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'analytics_table.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        },
        handleRowHover(shift) {
            // Logic when a row is hovered over
        },
        handleDriverClick(driver) {
            // Logic when a driver is clicked
        },
        handleCSVChange(csv) {
            this.csv = csv;
        },

        async handleQuery() {
            this.isFetchingData = true;
            const queryData = {
                driverNumber: this.selectedEmployee ? this.selectedEmployee.id : null,
                licenseNumber: this.selectedCar ? this.selectedCar.id : null,
                rangeStartAt: formatISO(this.selectedFrom),
                rangeEndAt: this.selectedTo
                    ? formatISO(this.selectedTo)
                    : formatISO(this.selectedFrom),
            };
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/shifts`;
                const result = await axios.post(url, queryData, {
                    withCredentials: true,
                });

                this.$emit('onFinishLoading');
                this.startAt = this.selectedFrom;
                this.endAt = this.selectedTo;
                this.shifts = result.data;
            } catch (error) {
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
            } finally {
                this.isFetchingData = false;
            }
        },
        calculateTotalRevenue(shifts) {
            return shifts.reduce((acc, shift) => acc + shift.finalTotalAmount, 0);
        },
    },
    mounted() {
        this.handleQuery();
    },
};
</script>

<style lang="scss">
.Analytics {
    @extend %contentWrapper;
    @extend %page;
}
</style>
