<template>
    <div class="analytics-table">
        <table v-if="sortedDrivers.length > 0">
            <thead>
                <tr>
                    <th
                        :class="[
                            { isSorted: currentSortColumn === 'name' },
                            { asc: currentSortOrder === 'asc' },
                            { desc: currentSortOrder === 'desc' },
                        ]"
                        @click="sortTable('name', 'string')"
                    >
                        Fahrer
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 16l-6-6h12l-6 6z" fill="currentColor"/></svg>
                    </th>
                    <th
                        v-if="
                            selectedCars
                                ? sortedCars[0].performanceScore
                                : sortedDrivers[0].performanceScore
                        "
                        :class="[
                            { isSorted: currentSortColumn === 'performanceScore' },
                            { asc: currentSortOrder === 'asc' },
                            { desc: currentSortOrder === 'desc' },
                        ]"
                        @click="sortTable('performanceScore')"
                    >
                        Performance
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 16l-6-6h12l-6 6z" fill="currentColor"/></svg>
                    </th>
                    <th
                        :class="[
                            { isSorted: currentSortColumn === 'finalTotalAmount' },
                            { asc: currentSortOrder === 'asc' },
                            { desc: currentSortOrder === 'desc' },
                        ]"
                        @click="sortTable('finalTotalAmount')"
                    >
                        Umsatz
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 16l-6-6h12l-6 6z" fill="currentColor"/></svg>
                    </th>
                    <th
                        :class="[
                            { isSorted: currentSortColumn === 'averageRevenuePerKm' },
                            { asc: currentSortOrder === 'asc' },
                            { desc: currentSortOrder === 'desc' },
                        ]"
                        @click="sortTable('averageRevenuePerKm')"
                    >
                        KM Schnitt
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 16l-6-6h12l-6 6z" fill="currentColor"/></svg>
                    </th>
                    <th
                        :class="[
                            { isSorted: currentSortColumn === 'emptyPercentage' },
                            { asc: currentSortOrder === 'asc' },
                            { desc: currentSortOrder === 'desc' },
                        ]"
                        @click="sortTable('emptyPercentage')"
                    >
                        Leerquote
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 16l-6-6h12l-6 6z" fill="currentColor"/></svg>
                    </th>
                    <th
                        :class="[
                            { isSorted: currentSortColumn === 'totalDistance' },
                            { asc: currentSortOrder === 'asc' },
                            { desc: currentSortOrder === 'desc' },
                        ]"
                        @click="sortTable('totalDistance')"
                    >
                        Gesamt Km
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 16l-6-6h12l-6 6z" fill="currentColor"/></svg>
                    </th>
                    <th
                        :class="[
                            { isSorted: currentSortColumn === 'hiredDistance' },
                            { asc: currentSortOrder === 'asc' },
                            { desc: currentSortOrder === 'desc' },
                        ]"
                        @click="sortTable('hiredDistance')"
                    >
                        Besetzt Km
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 16l-6-6h12l-6 6z" fill="currentColor"/></svg>
                    </th>
                    <th
                        :class="[
                            { isSorted: currentSortColumn === 'dailyRevenues' },
                            { asc: currentSortOrder === 'asc' },
                            { desc: currentSortOrder === 'desc' },
                        ]"
                        @click="sortTable('dailyRevenues')"
                    >
                        Leer Km
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 16l-6-6h12l-6 6z" fill="currentColor"/></svg>
                    </th>
                    <th
                        :class="[
                            { isSorted: currentSortColumn === day },
                            { asc: currentSortOrder === 'asc' },
                            { desc: currentSortOrder === 'desc' },
                        ]"
                        v-for="day in daysInMonth"
                        @click="sortTable(day)"
                        :key="day.getTime()"
                    >
                        {{ format(day, 'dd.MM') }}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 16l-6-6h12l-6 6z" fill="currentColor"/></svg>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="driver in selectedCars ? sortedCars : sortedDrivers"
                    :key="selectedCars ? driver.licenseNumber : driver.name"
                >
                    <td
                        style="text-align: left; position: sticky; left: -1px; background-color: var(--color-white);"
                    >
                        <b>
                            {{ selectedCars ? driver.licenseNumber : driver.name }}
                        </b>
                    </td>
                    <td
                        :class="performanceClass(driver.performanceScore)"
                        v-if="
                            selectedCars
                                ? sortedCars[0].performanceScore
                                : sortedDrivers[0].performanceScore
                        "
                    >
                        {{ (driver.performanceScore * 100 || 0).toFixed(2) }}%
                    </td>
                    <td>{{ formatCurrency(driver.finalTotalAmount) }}</td>
                    <td
                        :class="averageRevenuePerKmClass(driver.averageRevenuePerKm)"
                        style="text-align: right"
                    >
                        {{ formatAverage(driver.averageRevenuePerKm) }}
                    </td>
                    <td
                        :class="emptyPercentageClass(driver.emptyPercentage)"
                        style="text-align: right"
                    >
                        {{ formatPercentage(driver.emptyPercentage) }}
                    </td>
                    <td>{{ formatDistance(driver.totalDistance) }}</td>
                    <td>{{ formatDistance(driver.hiredDistance) }}</td>
                    <td>{{ formatDistance(driver.forHireDistance) }}</td>
                    <td
                        v-for="day in daysInMonth"
                        :key="day.getTime()"
                        :class="
                            revenueClass(
                                driver.dailyRevenues.find(d => d.day.getTime() === day.getTime())
                                    .amount,
                            )
                        "
                    >
                        {{
                            formatCurrency(
                                driver.dailyRevenues.find(d => d.day.getTime() === day.getTime())
                                    .amount,
                            )
                        }}
                    </td>
                </tr>
            </tbody>
        </table>
        <EmptyState v-else />
    </div>
</template>

<script>
import { fakerDE } from '@faker-js/faker';
import { fakerTR } from '@faker-js/faker';
import { fakerAR } from '@faker-js/faker';
import { addDays, endOfMonth, format, startOfMonth } from 'date-fns';
import { round2d } from '@/lib/helper';
import EmptyState from '@/components/EmptyState.vue';

export default {
    name: 'AnalyticsTable',
    components: {
        EmptyState,
    },

    props: {
        startAt: Date,
        endAt: Date,
        shifts: Array,
        option: Object,
    },
    data: () => {
        return {
            format,
            round2d,
            currentSortColumn: 'performanceScore',
            currentSortOrder: 'desc',
            dailyRevenue: {},
        };
    },
    watch: {
        option: {
            handler(value) {
                if (value.id === 'car') {
                    this.currentSortColumn = 'performanceScore';
                } else {
                    this.currentSortColumn = 'performanceScore';
                }
            },
            immediate: true,
            deep: true,
        },
        sortedDrivers: {
            handler() {
                setTimeout(() => {
                    const cvs = this.createCSV({ option: 'driver' });
                    this.$emit('onCVSChange', cvs);
                }, 100);
            },
            immediate: true,
            deep: true,
        },
        sortedCars: {
            handler() {
                setTimeout(() => {
                    const cvs = this.createCSV({ option: 'car' });
                    this.$emit('onCVSChange', cvs);
                }, 100);
            },
            immediate: true,
            deep: true,
        },
    },
    computed: {
        selectedCars() {
            return this.option.id === 'car';
        },
        sortedCars() {
            return [...this.carsWithPerformance].sort((a, b) =>
                this.compareValues(a, b, this.currentSortColumn),
            );
        },
        sortedDrivers() {
            return [...this.driverWithPerformance].sort((a, b) =>
                this.compareValues(a, b, this.currentSortColumn),
            );
        },
        cars() {
            const cars = Object.groupBy(this.shifts, shift => shift.licenseNumber);
            const processedCars = this.processShifts(cars);
            return Object.values(processedCars);
        },
        drivers() {
            const driver = Object.groupBy(this.shifts, ({ driver }) => driver.driverNumber);
            const processedDriver = this.processShifts(driver);
            return Object.values(processedDriver);
        },
        carsWithPerformance() {
            return this.cars.map(car => ({
                ...car,
                performanceScore: this.calculatePerformanceScoreCar(car),
            }));
        },
        driverWithPerformance() {
            return this.drivers.map(driver => ({
                ...driver,
                performanceScore: this.calculatePerformanceScoreDriver(driver),
            }));
        },
        daysInMonth() {
            const today = new Date(this.startAt);
            const start = startOfMonth(today);
            const end = endOfMonth(today);
            const days = [];
            for (let day = start; day <= end; day = addDays(day, 1)) {
                days.push(day);
            }
            // only show days that are in the past or today
            return days.filter(day => day <= new Date());
        },
        shiftsGroupedByDriver() {
            return Object.groupBy(this.shifts, ({ driver }) => driver.driverNumber);
        },
        shiftsGroupedByCar() {
            return Object.groupBy(this.shifts, shift => shift.licenseNumber);
        },
    },
    methods: {
        handleRandomName() {
            const names = {
                0: `${fakerDE.person.firstName()} ${fakerDE.person.lastName()}`,
                1: `${fakerTR.person.firstName()} ${fakerTR.person.lastName()}`,
                2: `${fakerAR.person.firstName()} ${fakerAR.person.lastName()}`,
            };
            return names[Math.floor(Math.random() * 3)];
        },
        processShifts(givenDriver) {
            const driver = { ...givenDriver };
            for (const driverNumber in driver) {
                const driverShifts = driver[driverNumber];
                const finalTotalAmount = driverShifts.reduce(
                    (sum, shift) => sum + shift.finalTotalAmount,
                    0,
                );
                const forHireDistance = driverShifts.reduce(
                    (sum, shift) => sum + shift.forHireDistance,
                    0,
                );
                const hiredDistance = driverShifts.reduce(
                    (sum, shift) => sum + shift.hiredDistance,
                    0,
                );
                const totalDistance = driverShifts.reduce(
                    (sum, shift) => sum + shift.totalDistance,
                    0,
                );
                const emptyPercentage = (totalDistance - hiredDistance) / totalDistance || 0;
                const averageRevenuePerKm = finalTotalAmount / (totalDistance || 1);
                const dailyRevenues = this.daysInMonth.map(day => ({
                    day,
                    amount: this.getDailyRevenue({ driverNumber }, day),
                }));
                driver[driverNumber] = {
                    ...driver[driverNumber][0].driver,
                    ...driver[driverNumber][0],
                    finalTotalAmount: finalTotalAmount,
                    forHireDistance: forHireDistance,
                    totalDistance: totalDistance,
                    emptyPercentage: emptyPercentage,
                    averageRevenuePerKm: averageRevenuePerKm,
                    hiredDistance: hiredDistance,
                    dailyRevenues,
                    hasShifts: driverShifts.length > 0,
                };
            }
            return driver;
        },
        createCSV({ option }) {
            let csvContent = '';
            // Header
            csvContent +=
                'Fahrer,Performance,Umsatz,KM Schnitt,Leerquote,Gesamt Km,Besetzt Km,Leer Km,';
            this.daysInMonth.forEach(day => {
                csvContent += `${this.format(day, 'dd.MM')},`;
            });
            csvContent += '\r\n';
            const drivers = option === 'car' ? this.sortedCars : this.sortedDrivers;
            // Rows
            drivers.forEach(driver => {
                const formattedTotalAmount = this.formatCurrency(
                    driver.finalTotalAmount / 100,
                ).replace(',', '.');
                const formattedPerformanceScore = (driver.performanceScore || 0).toFixed(2) || 0;
                const formattedAverageRevenuePerKm = this.formatAverage(
                    driver.averageRevenuePerKm,
                ).replace(',', '.');
                const formattedEmptyPercentage = this.formatPercentage(
                    driver.emptyPercentage,
                ).replace(',', '.');
                const formattedTotalDistance = this.formatDistance(driver.totalDistance).replace(
                    ',',
                    '.',
                );
                const formattedHiredDistance = this.formatDistance(driver.hiredDistance).replace(
                    ',',
                    '.',
                );
                const formattedForHireDistance = this.formatDistance(
                    driver.forHireDistance,
                ).replace(',', '.');
                csvContent += `${driver.name},${formattedPerformanceScore},${formattedTotalAmount},${formattedAverageRevenuePerKm},${formattedEmptyPercentage},${formattedTotalDistance},${formattedHiredDistance},${formattedForHireDistance},`;
                this.daysInMonth.forEach(day => {
                    const dailyRevenue = this.getDailyRevenue(driver, day);
                    const formattedDailyRevenue = this.formatCurrency(dailyRevenue / 100).replace(
                        ',',
                        '.',
                    );
                    csvContent += `${formattedDailyRevenue},`;
                });
                csvContent += '\r\n';
            });
            return csvContent;
        },
        normalizeValue(value, min, max) {
            return (value - min) / (max - min);
        },
        getMetricMinMax(metric, option) {
            const values =
                option === 'car'
                    ? this.cars.map(car => car[metric])
                    : this.drivers.map(driver => driver[metric]);
            return {
                min: Math.min(...values),
                max: Math.max(...values),
            };
        },
        calculatePerformanceScoreDriver(driver) {
            const weights = {
                finalTotalAmount: 0.4,
                averageRevenuePerKm: 0.2,
                emptyPercentage: 0.1,
                totalDistance: 0.1,
                hiredDistance: 0.1,
                forHireDistance: 0.1,
            };
            const metrics = [
                'finalTotalAmount',
                'averageRevenuePerKm',
                'emptyPercentage',
                'totalDistance',
                'hiredDistance',
                'forHireDistance',
            ];
            let score = 0;
            metrics.forEach(metric => {
                const { min, max } = this.getMetricMinMax(metric);
                let normalizedValue = this.normalizeValue(Number(driver[metric]), min, max);
                // For 'emptyPercentage' and 'forHireDistance', higher values are worse, so we invert the normalization
                if (metric === 'emptyPercentage' || metric === 'forHireDistance') {
                    normalizedValue = 1 - normalizedValue;
                }
                score += normalizedValue * weights[metric];
            });
            return score;
        },
        calculatePerformanceScoreCar(car) {
            const weights = {
                finalTotalAmount: 0.4,
                averageRevenuePerKm: 0.2,
                emptyPercentage: 0.1,
                totalDistance: 0.1,
                hiredDistance: 0.1,
                forHireDistance: 0.1,
            };
            const metrics = [
                'finalTotalAmount',
                'averageRevenuePerKm',
                'emptyPercentage',
                'totalDistance',
                'hiredDistance',
                'forHireDistance',
            ];
            let score = 0;
            metrics.forEach(metric => {
                const { min, max } = this.getMetricMinMax(metric, 'car');
                let normalizedValue = this.normalizeValue(Number(car[metric]), min, max);
                // For 'emptyPercentage' and 'forHireDistance', higher values are worse, so we invert the normalization
                if (metric === 'emptyPercentage' || metric === 'forHireDistance') {
                    normalizedValue = 1 - normalizedValue;
                }
                score += normalizedValue * weights[metric];
            });
            return score;
        },
        sortTable(column) {
            if (this.currentSortColumn === column) {
                this.currentSortOrder = this.currentSortOrder === 'asc' ? 'desc' : 'asc';
            } else {
                this.currentSortColumn = column;
                this.currentSortOrder = 'desc';
            }
        },
        compareValues(a, b, column) {
            let valueA = String(a[column]).replace(',', '.');
            let valueB = String(b[column]).replace(',', '.');
            if (column instanceof Date) {
                valueA = this.getDailyRevenue(a, column);
                valueB = this.getDailyRevenue(b, column);
            }
            if (Number(valueA) && Number(valueB)) {
                valueA = Number(valueA);
                valueB = Number(valueB);
            }
            if (this.currentSortOrder === 'asc') {
                return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
            } else {
                return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
            }
        },
        formatDistance(distance) {
            return round2d(distance / 1000).format() + ' km';
        },
        formatCurrency(amount) {
            return round2d(amount / 100).format() + ' €';
        },
        formatPercentage(amount) {
            return round2d(amount).format() + '%';
        },
        formatAverage(amount) {
            return round2d(amount * 10).format() + ' €';
        },

        getDailyRevenue({ driverNumber }, day) {
            if (this.dailyRevenue[driverNumber] && this.dailyRevenue[driverNumber][day]) {
                return this.dailyRevenue[driverNumber][day];
            }
            const shifts =
                this.shiftsGroupedByDriver[driverNumber] || this.shiftsGroupedByCar[driverNumber];
            const dailyShifts = shifts.filter(shift => {
                const trips = shift.trips.filter(trip => {
                    const tripStart = new Date(trip.startAt);
                    return tripStart.getDate() === day.getDate();
                });

                return trips.length > 0;
            });
            const dailyRevenue = dailyShifts.reduce(
                (sum, shift) => sum + shift.finalTotalAmount,
                0,
            );
            if (!this.dailyRevenue[driverNumber]) {
                this.dailyRevenue[driverNumber] = {};
            }

            this.dailyRevenue[driverNumber][day] = dailyRevenue;
            return dailyRevenue;
        },
        revenueClass(amount) {
            if (amount === 0) return 'red';
            if (amount < 5000) return 'orange';
            if (amount < 10000) return 'yellow';
            return 'green';
        },
        averageRevenuePerKmClass(amount) {
            const average = Number(amount) * 10;
            if (average < 1.1) return 'red';
        },
        performanceClass(amount) {
            const performance = Number(amount);
            if (performance < 0.4) return 'red';
            if (performance < 0.5) return 'orange';
            if (performance < 0.6) return 'yellow';
            return 'green';
        },
        emptyPercentageClass(amount) {
            const percentage = amount;
            if (percentage === 0) return '';
            if (percentage < 0.4) return 'green';
            if (percentage < 0.5) return 'yellow';
            if (percentage < 0.6) return 'orange';
            return 'red';
        },
    },
};
</script>

<style scoped lang="scss">
.theme-dark {
    .analytics-table {
        .red {
            background-color: var(--color-red);
            color: var(--color-text-white);
        }
        .orange {
            background-color: var(--color-warning);
            color: var(--color-text-white);
        }
        .yellow {
            background-color: var(--color-yellow-start);
            color: var(--color-white);
        }
        .green {
            background-color: var(--color-green);
            color: var(--color-text-white);
        }
    }
}
.analytics-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
    font-size: 13px;
    border-collapse: collapse;
    table-layout: fixed;
    overflow: auto;
    max-height: calc(100vh - 240px);
    color: var(--color-text-black);

    table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid rgba(0, 0, 0, 0.05);

        th,
        td {
            padding: 4px 8px;
            outline: 1px solid rgba(0, 0, 0, 0.05);
            text-align: right;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            min-width: 20px;
        }

        th {
            background-color: var(--color-white);
            vertical-align: middle;
            text-align: left;
            position: sticky;
            top: -1px;
            z-index: 10;
            background-color: var(--color-white);
            outline: 1px solid var(--color-border);

            &:first-child {
                left: -1px;
                z-index: 20;
            }

            &:hover {
                cursor: pointer;
                svg {
                    opacity: 1;
                }
            }
            svg {
                margin-left: 5px;
                width: 14px;
                height: 14px;
                display: inline-block;

                transform: translateY(2px) rotate(180deg);
                opacity: 0;
                transition: opacity 0.1s ease-in-out;
            }

            &.isSorted {
                svg {
                    opacity: 1;
                }
            }

            &.asc {
                svg {
                    transform: translateY(2px) rotate(0deg);
                }
            }
        }

        tr:nth-child(even) {
            background-color: rgba(0, 0, 0, 0.05);
        }
    }

    .red {
        background-color: darken(#f9d1ce, 10);
        color: darken(#f9d1ce, 90);
        border: solid 1px darken(#f9d1ce, 30);
    }
    .orange {
        background-color: lighten(#fad7c1, 0);
        color: darken(#fad7c1, 80);
        border: solid 1px darken(#fad7c1, 30);
    }
    .yellow {
        background-color: darken(#fffbd6, 5);
        color: darken(#fffbd6, 90);
        border: solid 1px darken(#fffbd6, 60);
    }
    .green {
        background-color: lighten(#b6efcf, 0);
        color: darken(#b6efcf, 70);
        border: solid 1px darken(#b6efcf, 50);
    }
}
.virtual-scroller {
    width: 100%;
}
</style>
