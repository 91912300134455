var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "analytics-table"
  }, [_vm.sortedDrivers.length > 0 ? _c('table', [_c('thead', [_c('tr', [_c('th', {
    class: [{
      isSorted: _vm.currentSortColumn === 'name'
    }, {
      asc: _vm.currentSortOrder === 'asc'
    }, {
      desc: _vm.currentSortOrder === 'desc'
    }],
    on: {
      "click": function click($event) {
        return _vm.sortTable('name', 'string');
      }
    }
  }, [_vm._v(" Fahrer "), _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "svg-inline": '',
      "role": 'presentation',
      "focusable": 'false',
      "tabindex": '-1'
    }
  }, [_c('path', {
    attrs: {
      "d": "M12 16l-6-6h12l-6 6z",
      "fill": "currentColor"
    }
  })])]), (_vm.selectedCars ? _vm.sortedCars[0].performanceScore : _vm.sortedDrivers[0].performanceScore) ? _c('th', {
    class: [{
      isSorted: _vm.currentSortColumn === 'performanceScore'
    }, {
      asc: _vm.currentSortOrder === 'asc'
    }, {
      desc: _vm.currentSortOrder === 'desc'
    }],
    on: {
      "click": function click($event) {
        return _vm.sortTable('performanceScore');
      }
    }
  }, [_vm._v(" Performance "), _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "svg-inline": '',
      "role": 'presentation',
      "focusable": 'false',
      "tabindex": '-1'
    }
  }, [_c('path', {
    attrs: {
      "d": "M12 16l-6-6h12l-6 6z",
      "fill": "currentColor"
    }
  })])]) : _vm._e(), _c('th', {
    class: [{
      isSorted: _vm.currentSortColumn === 'finalTotalAmount'
    }, {
      asc: _vm.currentSortOrder === 'asc'
    }, {
      desc: _vm.currentSortOrder === 'desc'
    }],
    on: {
      "click": function click($event) {
        return _vm.sortTable('finalTotalAmount');
      }
    }
  }, [_vm._v(" Umsatz "), _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "svg-inline": '',
      "role": 'presentation',
      "focusable": 'false',
      "tabindex": '-1'
    }
  }, [_c('path', {
    attrs: {
      "d": "M12 16l-6-6h12l-6 6z",
      "fill": "currentColor"
    }
  })])]), _c('th', {
    class: [{
      isSorted: _vm.currentSortColumn === 'averageRevenuePerKm'
    }, {
      asc: _vm.currentSortOrder === 'asc'
    }, {
      desc: _vm.currentSortOrder === 'desc'
    }],
    on: {
      "click": function click($event) {
        return _vm.sortTable('averageRevenuePerKm');
      }
    }
  }, [_vm._v(" KM Schnitt "), _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "svg-inline": '',
      "role": 'presentation',
      "focusable": 'false',
      "tabindex": '-1'
    }
  }, [_c('path', {
    attrs: {
      "d": "M12 16l-6-6h12l-6 6z",
      "fill": "currentColor"
    }
  })])]), _c('th', {
    class: [{
      isSorted: _vm.currentSortColumn === 'emptyPercentage'
    }, {
      asc: _vm.currentSortOrder === 'asc'
    }, {
      desc: _vm.currentSortOrder === 'desc'
    }],
    on: {
      "click": function click($event) {
        return _vm.sortTable('emptyPercentage');
      }
    }
  }, [_vm._v(" Leerquote "), _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "svg-inline": '',
      "role": 'presentation',
      "focusable": 'false',
      "tabindex": '-1'
    }
  }, [_c('path', {
    attrs: {
      "d": "M12 16l-6-6h12l-6 6z",
      "fill": "currentColor"
    }
  })])]), _c('th', {
    class: [{
      isSorted: _vm.currentSortColumn === 'totalDistance'
    }, {
      asc: _vm.currentSortOrder === 'asc'
    }, {
      desc: _vm.currentSortOrder === 'desc'
    }],
    on: {
      "click": function click($event) {
        return _vm.sortTable('totalDistance');
      }
    }
  }, [_vm._v(" Gesamt Km "), _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "svg-inline": '',
      "role": 'presentation',
      "focusable": 'false',
      "tabindex": '-1'
    }
  }, [_c('path', {
    attrs: {
      "d": "M12 16l-6-6h12l-6 6z",
      "fill": "currentColor"
    }
  })])]), _c('th', {
    class: [{
      isSorted: _vm.currentSortColumn === 'hiredDistance'
    }, {
      asc: _vm.currentSortOrder === 'asc'
    }, {
      desc: _vm.currentSortOrder === 'desc'
    }],
    on: {
      "click": function click($event) {
        return _vm.sortTable('hiredDistance');
      }
    }
  }, [_vm._v(" Besetzt Km "), _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "svg-inline": '',
      "role": 'presentation',
      "focusable": 'false',
      "tabindex": '-1'
    }
  }, [_c('path', {
    attrs: {
      "d": "M12 16l-6-6h12l-6 6z",
      "fill": "currentColor"
    }
  })])]), _c('th', {
    class: [{
      isSorted: _vm.currentSortColumn === 'dailyRevenues'
    }, {
      asc: _vm.currentSortOrder === 'asc'
    }, {
      desc: _vm.currentSortOrder === 'desc'
    }],
    on: {
      "click": function click($event) {
        return _vm.sortTable('dailyRevenues');
      }
    }
  }, [_vm._v(" Leer Km "), _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "svg-inline": '',
      "role": 'presentation',
      "focusable": 'false',
      "tabindex": '-1'
    }
  }, [_c('path', {
    attrs: {
      "d": "M12 16l-6-6h12l-6 6z",
      "fill": "currentColor"
    }
  })])]), _vm._l(_vm.daysInMonth, function (day) {
    return _c('th', {
      key: day.getTime(),
      class: [{
        isSorted: _vm.currentSortColumn === day
      }, {
        asc: _vm.currentSortOrder === 'asc'
      }, {
        desc: _vm.currentSortOrder === 'desc'
      }],
      on: {
        "click": function click($event) {
          return _vm.sortTable(day);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.format(day, 'dd.MM')) + " "), _c('svg', {
      attrs: {
        "xmlns": "http://www.w3.org/2000/svg",
        "viewBox": "0 0 24 24",
        "width": "24",
        "height": "24",
        "svg-inline": '',
        "role": 'presentation',
        "focusable": 'false',
        "tabindex": '-1'
      }
    }, [_c('path', {
      attrs: {
        "d": "M12 16l-6-6h12l-6 6z",
        "fill": "currentColor"
      }
    })])]);
  })], 2)]), _c('tbody', _vm._l(_vm.selectedCars ? _vm.sortedCars : _vm.sortedDrivers, function (driver) {
    return _c('tr', {
      key: _vm.selectedCars ? driver.licenseNumber : driver.name
    }, [_c('td', {
      staticStyle: {
        "text-align": "left",
        "position": "sticky",
        "left": "-1px",
        "background-color": "var(--color-white)"
      }
    }, [_c('b', [_vm._v(" " + _vm._s(_vm.selectedCars ? driver.licenseNumber : driver.name) + " ")])]), (_vm.selectedCars ? _vm.sortedCars[0].performanceScore : _vm.sortedDrivers[0].performanceScore) ? _c('td', {
      class: _vm.performanceClass(driver.performanceScore)
    }, [_vm._v(" " + _vm._s((driver.performanceScore * 100 || 0).toFixed(2)) + "% ")]) : _vm._e(), _c('td', [_vm._v(_vm._s(_vm.formatCurrency(driver.finalTotalAmount)))]), _c('td', {
      class: _vm.averageRevenuePerKmClass(driver.averageRevenuePerKm),
      staticStyle: {
        "text-align": "right"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatAverage(driver.averageRevenuePerKm)) + " ")]), _c('td', {
      class: _vm.emptyPercentageClass(driver.emptyPercentage),
      staticStyle: {
        "text-align": "right"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatPercentage(driver.emptyPercentage)) + " ")]), _c('td', [_vm._v(_vm._s(_vm.formatDistance(driver.totalDistance)))]), _c('td', [_vm._v(_vm._s(_vm.formatDistance(driver.hiredDistance)))]), _c('td', [_vm._v(_vm._s(_vm.formatDistance(driver.forHireDistance)))]), _vm._l(_vm.daysInMonth, function (day) {
      return _c('td', {
        key: day.getTime(),
        class: _vm.revenueClass(driver.dailyRevenues.find(function (d) {
          return d.day.getTime() === day.getTime();
        }).amount)
      }, [_vm._v(" " + _vm._s(_vm.formatCurrency(driver.dailyRevenues.find(function (d) {
        return d.day.getTime() === day.getTime();
      }).amount)) + " ")]);
    })], 2);
  }), 0)]) : _c('EmptyState')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }